import { Route } from '@angular/router';

export const appRoutes: Route[] = [
  {
    path: 'auth',
    loadChildren: () =>
      import('./modules/auth/auth.routes').then((m) => m.AUTH_ROUTES),
  },
  {
    path: 'app',
    loadChildren: () =>
      import('./modules/logged/logged.routes').then((m) => m.LOGGED_ROUTES),
  },
  { path: '**', redirectTo: 'auth' },
];

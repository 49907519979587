import {
  ApplicationConfig,
  importProvidersFrom,
  provideZoneChangeDetection,
} from '@angular/core';
import { provideRouter, withComponentInputBinding } from '@angular/router';
import { provideQueryClientOptions } from '@ngneat/query';
import { provideHotToastConfig } from '@ngxpert/hot-toast';
import { HOT_TOAST_CONFIG } from '@surfrec/angular-core';
import {
  HTTP_INTERCEPTORS,
  HttpClientModule,
  provideHttpClient,
  withFetch,
} from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { provideTranslations } from './translate.providers';
import { registerLocaleData } from '@angular/common';
import localeEn from '@angular/common/locales/en';
import localePt from '@angular/common/locales/pt';
import { AuthInterceptor } from './_core/interceptors/auth.interceptor';
import { provideClientHydration } from '@angular/platform-browser';
import { appRoutes } from './app.routes';

registerLocaleData(localeEn);
registerLocaleData(localePt);

export const appConfig: ApplicationConfig = {
  providers: [
    provideClientHydration(),
    provideZoneChangeDetection({ eventCoalescing: true }),
    provideRouter(appRoutes, withComponentInputBinding()),
    provideQueryClientOptions({
      defaultOptions: {
        queries: { retry: false, refetchOnWindowFocus: false },
        mutations: { retry: false },
      },
    }),
    { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
    provideHotToastConfig(HOT_TOAST_CONFIG),
    provideHttpClient(withFetch()),
    ...provideTranslations,
    importProvidersFrom(HttpClientModule, BrowserAnimationsModule),
  ],
};

import { Provider } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import {
  MissingTranslationHandler,
  MissingTranslationHandlerParams,
  TranslateLoader,
  TranslateModule,
  TranslateService,
} from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

export class CustomMissingTranslationHandler
  implements MissingTranslationHandler
{
  handle(params: MissingTranslationHandlerParams) {
    return params.key;
  }
}

export const provideTranslations: Provider[] = [
  {
    provide: TranslateLoader,
    useFactory: HttpLoaderFactory,
    deps: [HttpClient],
  },
  {
    provide: MissingTranslationHandler,
    useClass: CustomMissingTranslationHandler,
  },
  TranslateService,
  TranslateModule.forRoot({
    useDefaultLang: true,
    missingTranslationHandler: {
      provide: MissingTranslationHandler,
      useClass: CustomMissingTranslationHandler,
    },
    loader: {
      provide: TranslateLoader,
      useFactory: HttpLoaderFactory,
      deps: [HttpClient],
    },
  }).providers!,
];

import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  BehaviorSubject,
  catchError,
  filter,
  Observable,
  of,
  switchMap,
  take,
  throwError,
} from 'rxjs';
import { AuthRepository } from '../../modules/auth/state/auth.repository';
import { AuthService } from '../../modules/auth/state/auth.service';
import { HotToastService } from '@ngxpert/hot-toast';
import { environment } from '../../../environments/environment';
import { RefreshTokenOutput } from '../../modules/auth/state/auth.types';
import { TranslateService } from '@ngx-translate/core';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  private _isRefreshing = false;
  private _refreshTokenSubject = new BehaviorSubject<boolean | null>(null);

  constructor(
    private readonly _authService: AuthService,
    private readonly _toastService: HotToastService,
    private readonly _authRepository: AuthRepository,
    private readonly _translateService: TranslateService,
  ) {}

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler,
  ): Observable<HttpEvent<any>> {
    return this._setHeaders(request.url).pipe(
      switchMap((headers: Record<string, any>) =>
        next.handle(request.clone({ setHeaders: headers })),
      ),
      catchError((err: any) => {
        if (
          err.status === 401 &&
          err.error?.errors?.[0]?.message === 'Token expired.'
        ) {
          return this._proceedWithRefreshToken(err, request, next);
        } else {
          this.showErrorToast(err, true);
        }
        return throwError(() => err);
      }),
    );
  }

  private _setHeaders(url: string): Observable<Record<string, any>> {
    return this._authRepository.token$.pipe(
      take(1),
      switchMap((token: string | null) =>
        url.includes(environment.apiUrl) && !url.includes('/auth/')
          ? of({ ...(token ? { Authorization: `Bearer ${token}` } : {}) })
          : of({}),
      ),
    );
  }

  private _proceedWithRefreshToken(
    err: any,
    request: HttpRequest<any>,
    next: HttpHandler,
  ): Observable<HttpEvent<any>> {
    return this._authRepository.refreshToken$.pipe(
      switchMap((refreshToken: string | null) => {
        if (!refreshToken) {
          return this._logoutUser(err);
        } else if (!this._isRefreshing) {
          return this._sendRefreshTokenRequest(
            err,
            request,
            next,
            refreshToken,
          );
        } else {
          return this.holdRequestsWhenIsRefreshing(err, request, next);
        }
      }),
    );
  }

  private _logoutUser(err: any): Observable<HttpEvent<any>> {
    if (typeof window !== 'undefined') this._authService.logout();
    return throwError(() => err);
  }

  private _sendRefreshTokenRequest(
    err: any,
    request: HttpRequest<any>,
    next: HttpHandler,
    refreshToken: string,
  ): Observable<HttpEvent<any>> {
    this._isRefreshing = true;
    this._refreshTokenSubject.next(null);

    return this._authService.refreshToken(refreshToken).pipe(
      switchMap((response: RefreshTokenOutput) => {
        this._authRepository.setToken(response.accessToken);
        this._authRepository.setRefreshToken(response.refreshToken);
        this._authRepository.setAuthDataToStorage({
          token: response.accessToken,
          refreshToken: response.refreshToken,
        });
        this._isRefreshing = false;
        this._refreshTokenSubject.next(true);
        return this._setHeaders(request.url).pipe(
          switchMap((headers: Record<string, any>) =>
            next.handle(request.clone({ setHeaders: headers })),
          ),
        );
      }),
      catchError(() => {
        this._isRefreshing = false;
        this._refreshTokenSubject.next(false);
        return this._logoutUser(err);
      }),
    );
  }

  private holdRequestsWhenIsRefreshing(
    err: any,
    request: HttpRequest<any>,
    next: HttpHandler,
  ): Observable<HttpEvent<any>> {
    return this._refreshTokenSubject.pipe(
      filter((data: boolean | null) => data != null),
      take(1),
      switchMap((data: boolean | null) => {
        if (data) {
          return this._setHeaders(request.url).pipe(
            switchMap((headers: Record<string, any>) =>
              next.handle(request.clone({ setHeaders: headers })),
            ),
          );
        } else {
          return throwError(() => err);
        }
      }),
    );
  }

  showErrorToast(err: HttpErrorResponse, interceptErrors: boolean) {
    const message = Array.isArray(err.error.message)
      ? err.error.message[0]
      : err.error.message;

    if (err.status === 403) {
      this._toastService.warning(
        this._translateService.instant(message ?? 'No access to the resource'),
      );
    } else if (err.status === 400) {
      this._toastService.warning(this._translateService.instant(message));
    } else if (interceptErrors && err.status >= 400 && err.status < 500) {
      this._toastService.warning(message);
    } else if (interceptErrors) {
      this._toastService.error('Internal server error');
    }
  }
}
